import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Animate from "components/animate/animate";
import "./style.scss";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";
import { getAnyOfValue } from "../../../utils/getCustomDataValues"

const Profile = () => {
  const { t } = useTranslation();
  const authConf = useContext(AuthConfigContext);
  const [formData, setFormData] = useState({});
  const [displayInfo, setDisplayInfo] = useState([]);
  const [profileDetails] = useContext(ProfileDetailsContext);

  const deleted_fav_stores = [
    {
      type: "string",
      title: "イオンモール京都桂川店",
      enum: ["30303"],
    },
    {
      type: "string",
      title: "ららぽｰと和泉店",
      enum: ["31006"],
    },
    {
      type: "string",
      title: "そごう広島店",
      enum: ["40206"],
    },
    {
      type: "string",
      title: "ゆめタウン大牟田",
      enum: ["30503"],
    },
    {
      type: "string",
      title: "仙台三越店",
      enum: ["40105"],
    },
    {
      type: "string",
      title: "池袋西武本店",
      enum: ["40201"],
    },
    {
      type: "string",
      title: "グランベリーパーク南町田店",
      enum: ["31601"]
    },
    {
      type: "string",
      title: "サクラマチ熊本店",
      enum: ["30806"]
    }
  ];


  useEffect(() => {
    const getFormData = async () => {
      if(authConf &&
        authConf.configJson &&
        authConf.configJson.form_schema &&
        authConf.configJson.form_schema.properties
      ) {
        const formProperties = authConf.configJson.form_schema.properties
        if (!formProperties["custom_data"]) {
          setFormData(formProperties)
        } else {
          let customData = formProperties.custom_data.properties;
          const flattenedFormData = {...formProperties, ...customData}
          delete flattenedFormData.custom_data
          setFormData(flattenedFormData)
        }
      }
    };
    getFormData();
  }, [authConf]);

  useEffect(() => {
    const arrangeInformation = () => {
      let displayInformation = [];
      for(let item in formData) {
        if(!profileDetails.hasOwnProperty(item)) {
          // if profileDetails doesn't have this item, check in profileDetails.custom_data. Either push the title & value or just title to displayInformation
          if(profileDetails.custom_data && profileDetails.custom_data.hasOwnProperty(item)) {
            let combined
            if(item === 'favorite_store') {
              const fav_stores_source = Object.freeze({ ...authConf.configJson.form_schema?.properties?.custom_data?.properties?.favorite_store })
              // append with deleted lists
              const modified_fav_stores_source = { ...fav_stores_source, anyOf: [...fav_stores_source.anyOf, ...deleted_fav_stores] }
              combined = {
                name: formData[item].title,
                value: getAnyOfValue(checkArray(profileDetails.custom_data[item]), modified_fav_stores_source)
              }
            } else if(item === 'prefCode') {
              combined = {
                name: formData[item].title,
                value: getAnyOfValue(checkArray(profileDetails.custom_data[item]), authConf.configJson.form_schema?.properties?.custom_data?.properties?.prefCode)
              }
            } else {
              combined = {
                name: formData[item].title,
                value: checkArray(profileDetails.custom_data[item])
              }
            }
            // If item is agreement, do not display it's text in the profileDetails page
            if(notAgreement(combined)) displayInformation.push(combined);
          }
          else {
            const combined = {
              name: formData[item].title,
              value: ""
            }
            if(notAgreement(combined)) displayInformation.push(combined);
          }
        }
        // empty name if login with social
        else if (item === "name" && profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length) {
          const combined = {
            name: formData[item].title,
            value: ""
          }
          if(notAgreement(combined)) displayInformation.push(combined);
        }
        // if there's duplicate data in profileDetails and profileDetails.custom_data
        else if(profileDetails.hasOwnProperty(item) && profileDetails.custom_data && profileDetails.custom_data.hasOwnProperty(item)) {
          const combined = {
            name: formData[item].title,
            value: checkArray(profileDetails.custom_data[item])
          }
          if(notAgreement(combined)) displayInformation.push(combined);
        }
        // correctly show gender values for Japanese
        else if(item === "gender" && profileDetails["gender"] && formData[item].title === "性別") {
          const kanjiGender = profileDetails[item] === "male" ? "男性" : profileDetails[item] === "female" ? "女性" : profileDetails[item] === "other" ? "選択なし" : profileDetails[item]
          const genderCombined = {
            name: formData[item].title,
            value: kanjiGender
          }
          displayInformation.push(genderCombined);
        }
        // correctly show birthday values
        else if(item === "birthdate") {
          let birthdateCombined = {
            name: formData[item].title,
            value: ""
          };
          if(profileDetails["birthdate"]) {
            // const arrangedBday = profileDetails.birth_year && profileDetails.birth_month && profileDetails.birth_day
            //   ? `${profileDetails.birth_year}-${profileDetails.birth_month}-${profileDetails.birth_day}`
            //   : ""
            const arrangedBday = `${profileDetails.birthdate}`
            birthdateCombined = {
              name: formData[item].title,
              value: arrangedBday
            };
          }
          displayInformation.push(birthdateCombined);
        }
        else if(profileDetails.hasOwnProperty(item)) {
          const combined = {
            name: formData[item].title,
            value: profileDetails[item]
          }
          if(notAgreement(combined)) displayInformation.push(combined);
        }
      }
      setDisplayInfo(displayInformation);
    };
    const formDataIsEmpty = Object.keys(formData).length === 0;
    if(profileDetails !== null && !formDataIsEmpty) {
      arrangeInformation();
    }
  }, [profileDetails, formData, authConf]);

  const checkArray = (values) => {
    if(Array.isArray(values)) {
      return values.join(", ")
    }
    else if(values === true) {
      return '承認';
    } 
    else if(values === false) {
      return '不可';
    } else {
      return values
    }
  };

  const notAgreement = (item) => {
    if(item.name !== "利用規約に同意の上、保存してください。") return true;
  }


  return (
    <Animate>
      <div className="profile-page">
        <div className="card">
          <div className="card-title">
            <h1>{t("profile_page.title")}</h1>
            <Link className="edit" to="/profile/edit">
              <i className="fas fa-pen"></i>
              {t("profile_page.btn_edit")}
            </Link>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            {displayInfo && displayInfo.length > 0 ? (
              <ul className="list-group">
                {displayInfo.map((info, i) => (
                  <li className="list-group-item" key={i}>
                    <div className="label">{info.name}</div>
                    <div className="value">{info.value ? info.value : "-"}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                <div className="no-schema">
                  <span>{t("common.no_info")}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Profile;
